import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const Content = styled.div`
  max-width: 860px;
  padding: 1rem 1.0875rem;
  font-size: 1.2rem;
`

const NavLink = styled(Link)`
  color: black;
  margin-left: 15px;
  text-decoration: none;
  display: inline-block;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

const GitHubLink = styled.a`
  color: black;
  margin-left: 15px;
  text-decoration: none;
  display: inline-block;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

const HomeLink = styled(NavLink)`
  margin-left: 0;
`

const SiteHeader = styled.header`
  background: #fff;
  display: flex;
  align-content: center;
  justify-content: center;
`

const LogoContainer = styled.div`
  width: 3rem;
`

const Logo = styled(Img)`
  max-width: 100%;
  object-fit: contain;
`

export default function Image() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "MBlogo.png" }) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <SiteHeader>
      <Content>
        <LogoContainer>
          <Link to="/">
            <Logo fluid={data.file.childImageSharp.fluid} />
          </Link>
        </LogoContainer>
        {/* <p>
        <HomeLink to="/blog">{siteTitle}</HomeLink>
        <NavLink to="/blog">Blog</NavLink>
        <GitHubLink href="https://github.com/niklasmtj/gatsby-starter-julia">
          GitHub
        </GitHubLink>
      </p> */}
      </Content>
    </SiteHeader>
  )
}
